.footer {
    background: var(--blue-gray);
    color: var(--white);
}

.copyright {
    background: var(--dark-blue-gray);
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 2em;
}

.footer-link {
    color: var(--white);
    text-decoration: none;
    transition: 0.3s ease-in-out all;
}

.footer-link:hover {
    color: var(--green);
    transition: 0.3s ease-in-out all;
}

.footer-icons {
    color: var(--green);
    font-size: 2em;
}

.padding-right {
    padding-right: 60px;
}
.presentation {
    background: var(--blue-gray);
    color: var(--white);
}

.title-border {
    background: var(--green);
    width: 150px;
    height: 2px;
    margin: 20px auto 20px auto;
}

.card {
    background: var(--light-blue-gray);
    border-radius: 0;
    padding: 25px;
    margin: 25px;
}

.card-title {
    font-weight: bold;
}

.icons {
    font-size: 2.5em;
    color: var(--green);
    margin-bottom: 20px;
}
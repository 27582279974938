.score {
    background: var(--blue-gray);
    min-height: 550px;
}

.score-image {
    background-image: url('https://plus.unsplash.com/premium_photo-1661724305506-0a9c6c7f0422?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1544&q=80');
    width: 100%;
    min-height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -10px;
}

.score-image-backdrop {
    background: rgba(0,0,0,0.8);
    min-height: 600px;
    height: 100%;
    width: 100%;
}

.counter-digits {
    font-size: 3.5em;
    font-weight: bold;
}

.counter-text {
    padding-left: 40px;
}










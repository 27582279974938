.contact {
    background: var(--blue-gray);
    color: var(--white);
}

.map-wrap {
    height: 500px;
    width: 100vw;
    overflow: hidden;
}

.map-holder {
    background: url('../Images/map.jpeg') no-repeat center center;
    height: 100%;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transition: 0.5s ease-in-out;

}

.map-wrap:hover .map-holder {
    transition: 0.5s ease-in-out;
    transform: scale(1.2);
}

.map-backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
}

.hot-link {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out all;
}

.hot-link:hover {
    background: rgba(0,0,0,0.5);
    transition: 0.3s ease-in-out all;
}

.custom-input {
    background: none;
    outline: var(--green);
    border: none;
    border-bottom: 1px solid var(--light-blue-gray);
    color: var(--white);
    border-radius: 0;
}

.custom-input:focus {
    border: none;
    border-bottom: 1px solid var(--green);
    box-shadow: none;
    background: none;
    border-radius: 0;
    color: var(--white);
}

.message-card {
    border: 1px solid var(--light-blue-gray);
    padding: 40px;
}

.media-link {
    color: var(--green);
    transition: 0.3s ease-in-out all;
}

.media-link:hover {
    transform: scale(1.2);
    transition: 0.3s ease-in-out all;
    color: var(--green);
}
.wash-color {
    background: rgba(223, 223, 223, 0.45);
    height: 100vh;
    width: 100vw;
}

.backdrop {
    background: rgba(0, 0, 0, 0.55);
    height: 100vh;
    width: 100vw;
}

.header-big-image {
    background: url("https://commonslibrary.parliament.uk/content/uploads/2018/02/The_City_London-scaled.jpg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

h1 {
    font-size: 4em;
}

.hero-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.hero-title {
    width: 100%;
    text-align: center;
    margin-top: -60px;
}

.hero-paragraph {
    text-align: center;
    width: 100%;
    margin-top: 2em;
}


.content-page {
    background: var(--blue-gray);
    color: white;
}

.next-banner {
    min-height: 400px;
    background: var(--dark-blue-gray);
}

.next-service-img {
    min-height: 400px;
}

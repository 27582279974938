.carousel-wrapper {
    background: var(--blue-gray);
    color: var(--white);
}

.carousel-status {
    display: none;
}

.legend {
    opacity: 0.5!important;
    font-size: 1em!important;
    border-radius: 0!important;
    width: 100%!important;
    left: 0!important;
    margin-left: 0!important;
}

.legend:hover {
    opacity: 1!important;
}

.dot.selected {
    background: var(--green)!important;
}

.services-wrapper {
    background: var(--dark-blue-gray);
    width: 100vw;
}

.services-link {
    text-decoration: none;
    color: var(--white);
}

.services-link:hover {
    text-decoration: none;
    color: var(--white);
}

.services-link:hover .services-image {
    transform: scale(1.1);
    transition: 0.3s ease-in-out all;
}

.services-card {
    margin-bottom: 50px;
    padding: 10px;
}

.services-image-wrapper {
    width: 100%;
    height: 297px!important;
    overflow: hidden;
}

.services-image {
    height: 100%;
    transition: 0.3s ease-in-out all;
}

.services-separator {
    background: var(--green);
    height: 2px;
    width: 50px;
}
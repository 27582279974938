.testimonials-bg {
    background: var(--dark-blue-gray);
    color: var(--white);
}

body {
    background-color: #000;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.card-testimonials {
    position: relative;
    border: solid 1px var(--light-blue-gray);
    padding: 45px 50px 25px 60px;
    margin-top: 50px;
    background-color: var(--light-blue-gray);
    border-radius: 10px;
    color: #fff;
}

.post-txt {
    /*font-size: 20px;*/
    margin-bottom: 0;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 20px solid var(--light-blue-gray);
    margin-left: 85px;
}

.fit-image{
    width: 100%;
    object-fit: cover;
}

.profile-pic {
    position: relative;
    width: 120px!important;
    height: 120px!important;
    border-radius: 100%;
    left: 50px;
    margin-top: 15px;
}

.profile-name {
    position: relative;
    /*font-size: 22px;*/
    margin-top: -90px;
    left: 190px;
}

.profile-type {
    position: relative;
    margin-top: -60px;
    left: 190px;
    color: var(--green);
}

.carousel-item{
    min-height: 400px;
}

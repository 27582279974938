@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,300;1,400;1,700&display=swap');

:root {
    --blue-gray: #282C34!important;
    --light-blue-gray: #374151!important;
    --dark-blue-gray: #1d2128 !important;
    --green: #23C55E!important;
    --white: #FFFFFF!important;
    --gray: #1b1e23 !important;
}

body {
    background: #282C34!important;
}

* {
    font-family: 'Raleway', sans-serif;
    font-weight: lighter;
    line-height: 2em;
}

.no-line-height {
    line-height: 1em!important;
}

h3, h4, h5 {
    line-height: 1.7em!important;
}

.color-blue-gray {
    color: var(--blue-gray);
}

.color-light-blue-gray {
    color: var(--light-blue-gray);
}

.color-dark-blue-gray {
    color: var(--dark-blue-gray);
}

.color-green {
    color: var(--green);
}

.color-white {
    color: var(--white);
}

.color-gray {
    color: var(--gray);
}

.background-blue-gray {
    background-color: var(--blue-gray);
}

.background-light-blue-gray {
    background-color: var(--light-blue-gray);
}

.background-dark-blue-gray {
    background-color: var(--dark-blue-gray);
}

.background-green {
    background-color: var(--green);
}

.background-white {
    background-color: var(--white);
}

.background-gray {
    background-color: var(--gray);
}

html {
    overflow-x: hidden;
}

.button {
    text-decoration: none;
    color: var(--white);
    padding: 8px 25px 8px 25px;
    border: 1px solid white;
    transition: 0.3s ease-in-out all;
}

.button:hover {
    text-decoration: none;
    padding: 8px 25px 8px 25px;
    border: 1px solid var(--green);
    color: var(--white);
    background: var(--green);
    transition: 0.3s ease-in-out all;
}

.button-symbol {
    padding-left: 10px;
    transition: 0.3s all ease-in-out;
}

.button:hover > .button-symbol {
    padding-left: 15px;
    transition: 0.3s all ease-in-out;
}

.btn-success {
    background: var(--green)!important;
    border: none!important;
    border-radius: 0!important;
    min-width: 100px!important;
}

.image-gallery-svg:hover,
.image-gallery-icon:hover{
    color: var(--green)!important;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 2.5em!important;
    }
}

.video-controls {
    width: 100vw;
}

.custom-video {
    height: 50vh;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .custom-video {
        height: 100%;
        overflow: hidden;
    }
}

.navbar {
    margin: 0 auto;
    transition: 0.1s ease-in-out all;

}

.nav-max {
    max-width: 1633px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.hover-animation {
    position: relative;
    color: var(--white);
    text-decoration: none;
}

.hover-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--green);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    transform: scaleX(0);

}

.hover-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.bg-dark {
    background: var(--blue-gray);
    transition: 0.1s ease-in-out all;
}

.navbar-toggler {
    outline: none!important;
    box-shadow: none!important;
    border: 0!important;
}

@media only screen and (max-width: 600px) {
    .hover-animation:hover::after {
        display: none;
    }
}